<template>
  <TsSection class="md:mt-6 mt-4">
    <TsTypography
      v-if="props.searchQuery && props.searchQuery.length > 0"
      append-class="mb-4 text-center"
      weight="normal"
      as="h1"
    >
      Getting results for "<strong>{{ searchQuery }}</strong
      >"
    </TsTypography>
    <TsTypography v-else append-class="mb-4 " weight="normal" as="h2">
      Search something interesting today!
    </TsTypography>

    <!-- <NuxtImg
      width="160"
      class="text-center mx-auto"
      src="/images/searchResultNotFound.svg"
    /> -->
    <div class="flex items-center justify-center">
      <div class="loader"></div>
    </div>
  </TsSection>
</template>
<script setup lang="ts">

type Props = {
  searchQuery?: string;
};

const props = withDefaults(defineProps<Props>(), {});
const route = useRoute();
onMounted(() => {

    if( props.searchQuery === undefined || props.searchQuery?.length <= 0) {

  }
})

useHead({
  title: "Toolstation | Search",
  meta: [{ name: "description", content: "search result" }],
});

const { isMobile } = useDevice();
</script>

<style scoped>
.box {
  border: 1px solid red;
}
.loader {
  @apply h-14 w-14 border-8 border-blue-200 border-t-blue-500 rounded-full;
  animation: spin 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
